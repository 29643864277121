<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Fiche lead #{{lead.id}}</h3>
        </div>
        <div class="card-title">
          <b-button block :variant="leadStatus.color" size="lg" style="font-size:1.20em">{{leadStatus.titre}}</b-button>
        </div>
        <div class="card-toolbar">
          <b-button variant="danger" size="sm" class="btn-icon mr-1" @click="save(true)"><i class="icon-md fas fa-trash"></i></b-button>
          <div class="mr-1">
            <b-dropdown size="sm" variant="danger" html='<i class="icon-md fas fa-envelope"></i>'>
              <b-dropdown-item v-for="t in tpls.filter(t => t.type === 'lead')" :key="t.id" @click="sendTpl(t.id)">{{t.titre}}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="mr-1" v-if="$store.role === 'isAdmin'">
            <b-dropdown size="sm" variant="primary" html='<i class="icon-md fas fa-user"></i>'>
              <b-dropdown-item v-for="u in users" :key="u.id" @click="changeUser(u.id)">{{u.prenom}} {{u.nom[0]}}.</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="mr-1" v-if="lead.status_id != 3">
            <b-dropdown size="sm" variant="dark" html='<i class="fas fa-thermometer-three-quarters"></i>'>
              <b-dropdown-item v-for="l in parametrage.leadStatus" :key="l.id" @click="changeStatus(l.id)" :active="l.id === lead.status_id">{{l.titre}}</b-dropdown-item>
            </b-dropdown>
          </div>          
          <b-button variant="info" size="sm" class="btn-icon mr-1" v-b-modal.calendar><i class="icon-md fas fa-calendar"></i></b-button>
          <b-button variant="success" size="sm" class="btn-icon mr-1" @click="save"><i class="icon-md fas fa-save"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="Commercial:">
                <b-form-input :value="users.find(u => u.id == lead.user_id).prenom" disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <AppForm  v-model="lead"></AppForm>
          <b-row>
            <b-col md="10">
              <b-form-group label="Envoyer une offre:">
                <model-select :options="parametrage.produits" placeholder="Selectionnez votre formation" v-model="devisEmail.produit_id"></model-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Envoyer une offre:">
                <b-button variant="success" class="btn-icon mr-1" @click="sendDevis()"><i class="icon-md fas fa-envelope"></i></b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Commentaire</h3>
        </div>
      </div>
      <div class="card-body">
        <b-form-group label="Commentaire:">
          <b-form-textarea v-model="lead.commentaire" rows="4" :readonly="$store.role === 'isUser'"></b-form-textarea>
        </b-form-group>
        <b-form-group label="Ajouter:">
          <b-input-group>
            <b-form-input v-model="commentaireTmp" type="text" required placeholder="Entre votre commentaire"></b-form-input>
            <template #append>
              <b-button variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <div class="card card-custom gutter-b" v-if="formations.length > 0">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Formations</h3>
        </div>
        <div class="card-toolbar">
          <b-button variant="success" size="sm" class="btn-icon mr-1" @click="toFormation()"><i class="icon-md fas fa-plus"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Titre</th>
              <th scope="col">Prix</th>
              <th scope="col">Statut</th>
              <th scope="col">Date create</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(formation,i) in formations" :key="i">
              <th scope="row"><a :href="'/#/formation/detail/'+formation.id">{{formation.id}}</a></th>
              <td>{{formation.produit.titre}}</td>
              <td>{{formation.produit.prix}}</td>
              <td>{{parametrage.formationStatus.find(s => s.id === formation.status_id).titre}}</td>
              <td>{{$store.back.timestampToDate(formation.date_create)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Historique</h3>
        </div>
      </div>
      <div class="card-body" style="height: 200px; overflow: scroll;">
        <b-row v-for="(l,i) in lead.logs" :key="i" class="mt-3">
          <b-col :style="i%2 == 0 ? '' : 'text-align: right;'">
            <b-button variant="light" size="sm" class=" mr-1" style="color:black">{{l.body}}</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal id="calendar" title="Calendrier" ref="calendar">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="calendarTmp.name" type="text" required placeholder="Entre le titre"></b-form-input>
        </b-form-group>
        <b-form-group label="Detail:">
          <b-form-input v-model="calendarTmp.detail" type="text" required placeholder="Entre le deail"></b-form-input>
        </b-form-group>
        <b-form-group label="Debut:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart"></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeStart"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Fin:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart" readonly></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeEnd"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="saveCal()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.calendar.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AppForm from "@/view/layout/custom/Form.vue";
import { ModelSelect } from 'vue-search-select';
export default {
  data(){
    return {
      logs:false,
      tpls:[],
      users:[],
      calDateStart:null,
      calTimeStart:"12:00",
      calTimeEnd:"12:30",
      calendarTmp:{
        name: null,
        start: null,
        end: null,
        detail: null,
        url:`/${location.hash}`
      },
      lead:{
        prenom: null,
        nom: null,
        telephone: null,
        mobile: null,
        email: null,
        user_id: null,
        status_id: null,
        source_id: null,
        date_create: null,
        date_update: null,
        status: null,
        commentaire: null,
        id: null,
        logs: []
      },
      formations:[],
      parametrage:{
        role:[],
        status:[],
        leadStatus:[],
        formationStatus:[],
        produits:[]
      },
      commentaireTmp:null,
      devisEmail:{
        tpl_id: 7,
        produit_id:null,
        lead_id:null
      }
    };
  },
  components: {
    AppForm,
    ModelSelect
  },
  computed: {
    leadStatus(){
      let status = this.parametrage.leadStatus.find(l => l.id === this.lead.status_id);
      if(status){
        return status;
      }else{
        return {
          id:1,
          titre:"A appeler",
          color:"primary"
        }
      }
    }
  },
  methods: {
    save(sup = false) {
      if(sup === true){
        this.lead.status = 0;
      }
      this.$store.back.ajax('/lead/update', this.lead, (res) => {
        if(res.status === true){
          this.lead = res.data;
          this.$bvToast.toast(`Vos modification sont sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          if(sup === true){
            this.$router.push({path:`/lead/liste`});
          }
        }
      });
    },
    changeUser(id){
      this.lead.user_id = id;
      this.save();
    },
    changeStatus(id){
      if(this.lead.status_id === id){
        return false;
      }
      var user = this.$store.back.getUser();
      var now = this.$store.back.now();
      if(!this.lead.logs){
        this.lead.logs = [];
      }
      this.lead.logs.push({
        user_id:user.id,
        module:"lead",
        old: this.lead.status_id,
        new: id,
        body: `Le lead a change de statut de '${this.parametrage.leadStatus.find(l => l.id === this.lead.status_id).titre}' a '${this.parametrage.leadStatus.find(l => l.id === id).titre}' par ${user.prenom} ${user.nom} le ${this.$store.back.timestampToDate(now)}`,
        date_create:now
      });
      this.lead.status_id = id;
      if(id === 3){
        this.toFormation();
      }
    },
    toFormation(){
      this.save();
      this.$store.back.ajax(`/lead/formation/${this.lead.id}`, null, (res) => {
        if(res.status === true){
          this.$router.push({path:`/formation/detail/${res.data.id}`});
        }
      });
    },
    saveCal(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
      this.$store.back.ajax('/calendrier/create', this.calendarTmp, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre rdv a ete sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$refs.calendar.hide();
        }
      });
    },
    sendTpl(id){
      var user = this.$store.back.getUser();
      var now = this.$store.back.now();
      if(!this.lead.logs){
        this.lead.logs = [];
      }
      this.lead.logs.push({
        user_id:user.id,
        module:"template",
        old: null,
        new: id,
        body: `Le mail type '${this.tpls.find(t => t.id === id).titre}' a ete envoye par ${user.prenom} ${user.nom} le ${this.$store.back.timestampToDate(now)}`,
        date_create:now
      });
      this.save();
      let params = {
        model:"lead",
        id:id,
        model_id:this.lead.id
      };
      this.$store.back.ajax('/template/send', params, (res) => {
        if(res.status === true){
          this.$bvToast.toast(res.data, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      })
    },
    addCommentaire(){
      var user = this.$store.back.getUser();
      var date = new Date();
      this.lead.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    sendDevis(){
      this.devisEmail.lead_id = this.lead.id;
      this.$store.back.ajax('/lead/devis', this.devisEmail, (res) => {
        if(res.status === true){
          var user = this.$store.back.getUser();
          var now = this.$store.back.now();
          this.lead.logs.push({
            user_id:user.id,
            module:"template",
            old: null,
            new: this.devisEmail.tpl_id,
            body: `Le mail type '${this.tpls.find(t => t.id === this.devisEmail.tpl_id).titre}' a ete envoye par ${user.prenom} ${user.nom} le ${this.$store.back.timestampToDate(now)}`,
            date_create:now
          });
          this.$bvToast.toast(`Votre offre a ete envoyée !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    }
  },
  watch: {
    calDateStart(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
    }
  },
  beforeMount(){
    let params = {
      modules: ["UserRole","LeadStatus","FormationStatus","Produit"]
    };
    this.$store.back.ajax('/parametrage/all', params, (res) => {
      if(res.status === true){
        this.parametrage.role = res.data['UserRole'];
        this.parametrage.status = res.data['status'];
        this.parametrage.leadStatus = res.data['LeadStatus'];
        this.parametrage.formationStatus = res.data['FormationStatus'];
        this.parametrage.produits = res.data['Produit'];
        this.parametrage.produits.forEach(item => {
          item.text = item.titre+' '+item.prix+'euro '+item.duree+' sem';
          item.value = item.id;
        });
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);

    this.$store.back.ajax(`/lead/${this.$route.params.id}`, null, (res) => {
      if(res.status === true){
        this.lead = res.data.lead;
        this.formations = res.data.formations;
        this.users = res.data.users;
        this.tpls = res.data.tpls;
      }
    });
  }
};
</script>
