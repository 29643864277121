<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label="Nom:">
          <b-form-input v-model="obj.nom" type="text" required placeholder="Entre le nom"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Prenom:">
          <b-form-input v-model="obj.prenom" type="text" required placeholder="Entre le prenom"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Email:">
          <b-form-input v-model="obj.email" type="text" required placeholder="Entre l'email"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Telephone:">
          <b-form-input v-model="obj.telephone" type="text" required placeholder="Entre le telephone"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Mobile:">
          <b-form-input v-model="obj.mobile" type="text" required placeholder="Entre le mobile"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "AppForm",
  model: {
    prop: 'obj',    
  },
  props: {
    obj: Object
  },
  /*data(){
    return {
      model:{
        nom:null,
        prenom:null,
        email:null,
        telephone:null,
        mobile:null
      }
    }
  }*/
};
</script>
